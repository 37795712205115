import React from "react"
import guarantidLogo from "../images/guarantidLogo.png"

const Home = ({ location }) => {
  return (
    <main className="min-h-screen min-w-screen flex justify-center align-middle items-center bg-">
      <div className="flex flex-col">
        <img src={guarantidLogo} className="w-50 m-auto"/>
        <p className="font-sans text-center text-3xl font-bold">
         Checkout
        </p>
        </div>
    </main>
  )
}

export default Home
